export const PickCompleteColumns = [
  { field: 'id', headerName: 'id', width: 150 },
  { field: 'PickCompleteDate', headerName: 'Date', width: 100 },
  { field: 'RequestNo', headerName: 'Request #', width: 200 },
  { field: 'OrderDate', headerName: 'Order Date', width: 180 },
  { field: 'OrderNumber', headerName: 'Order #', width: 100 },
  { field: 'StoreID', headerName: 'Store', width: 50 },
  { field: 'OrderStatus', headerName: 'Status', width: 130 },
  { field: 'OrderCreatedTime', headerName: 'Create Time', width: 200 },
  { field: 'OrderLastUpdatedTime', headerName: 'Update Time', width: 220 },
  {
    field: 'DeposcoRackBarcode',
    headerName: 'D. Rack Barcode',
    width: 150,
  },
  {
    field: 'DeposcoProductCode',
    headerName: 'D. Product Code',
    width: 120,
  },
  { field: 'ProductName', headerName: 'Product Name', width: 250 },
  {
    field: 'DeposcoPickQuantity',
    headerName: 'D. Pick Qty',
    width: 50,
  },
  { field: 'DeposcoItemStatus', headerName: 'D. Item Status', width: 120 },
  {
    field: 'LiftiansPickRackBarcode',
    headerName: 'L. Rack Barcode',
    width: 120,
  },
  { field: 'RackID', headerName: 'Rack ID', width: 100 },
  { field: 'ShortagePicking', headerName: 'Shortage Picking', width: 150 },
  {
    field: 'LiftiansPickedQuantity',
    headerName: 'L. Pick Qty',
    width: 50,
  },
  { field: 'StationID', headerName: 'Station ID', width: 100 },
  {
    field: 'LiftiansPickStatus',
    headerName: 'L. Pick Status',
    width: 120,
  },
  {
    field: 'LiftiansUpdatedTime',
    headerName: 'L. Updated Time',
    width: 200,
  },
];

import React from 'react';
import { Box, List, ListItem, ListItemText, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import '../app.css';

class Sidebar extends React.Component {
  render() {
    return (
      <Box className="sidebar">
        <List>
          <ListItem component={Link} to="/dashboard/">
            <img
              src="/HF-Stacked.png"
              alt="HollywoodFeed"
              style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
            />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/dashboard/inventory">
            <ListItemText primary="Inventory" />
          </ListItem>
          {/* <ListItem button component={Link} to="/dashboard/reports">
            <ListItemText primary="Reports" />
          </ListItem> */}
          <ListItem button component={Link} to="/dashboard/deposco">
            <ListItemText primary="Deposco" />
          </ListItem>
        </List>
      </Box>
    );
  }
}

export default Sidebar;

// AuthContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config.json';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(
          `http://${config.serverUrl}:${config.servicePort}/auth/check`,
          { withCredentials: true }
        );
        setUser(response.data.user);
        localStorage.setItem('user', JSON.stringify(response.data.user));
      } catch (error) {
        console.error('Not authenticated', error);
        localStorage.removeItem('user');
      }
    };

    checkAuth();
  }, []);

  const login = async (password) => {
    try {
      const response = await axios.post(
        `http://${config.serverUrl}:${config.servicePort}/auth/login`,
        { username: 'admin', password },
        { withCredentials: true }
      );
      setUser(response.data.user);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post(
        `http://${config.serverUrl}:${config.servicePort}/auth/logout`,
        {},
        { withCredentials: true }
      );
      setUser(null);
      localStorage.removeItem('user');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Button,
  Typography,
  TextField,
  Alert,
} from '@mui/material';
import { formatDate } from '../../util/DateParser';
import { DataGrid } from '@mui/x-data-grid';
import config from '../../config/config.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PickCompleteColumns } from '../../data/PickReportColumns';
import { downloadToCSV } from '../../util/DataToCSV';

const Deposco = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [pickData, setPickData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [requestNumber, setRequestNumber] = useState('');
  const [message, setMessage] = useState(null);
  const [alertStatus, setAlertStatus] = useState('success');
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (endDate == null) {
          const selectionDate = formatDate(startDate);
          const response = await fetch(
            `http://${config.serverUrl}:${config.servicePort}/report/pick/${selectionDate}`,
            {
              method: 'GET',
              credentials: 'include',
            }
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const responseData = await response.json();
          setPickData(responseData.results);
        } else {
          const sDate = formatDate(startDate);
          const eDate = formatDate(endDate);
          const response = await fetch(
            `http://${config.serverUrl}:${config.servicePort}/report/pick?startDate=${sDate}&endDate=${eDate}`,
            {
              method: 'GET',
              credentials: 'include',
            }
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const responseData = await response.json();
          setPickData(responseData.results);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [startDate, endDate]);

  const handleDownloadCSV = () => {
    let dateString = formatDate(startDate);
    if (endDate !== null) {
      dateString += '-' + formatDate(endDate);
    }
    const data = pickData.map(({ id, ...rest }) => rest);
    const fileName = `Pick_Report_${dateString}`;
    downloadToCSV(data, fileName);
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleSendPickComplete = async () => {
    setMessage(null);
    setAlertStatus('success');
    if (!orderNumber.trim()) {
      setMessage('Please enter a value');
      setAlertStatus('error');
      return;
    }
    const parsedValue = parseInt(orderNumber);
    if (isNaN(parsedValue)) {
      setMessage('Please enter a valid order number.');
      setAlertStatus('error');
      return;
    }
    // If the value is an integer, call your API here
    try {
      const response = await fetch(
        `http://${config.serverUrl}:${config.servicePort}/report/sendDeposcoPickComplete/${parsedValue}`
      );
      const data = await response.json();
      // console.log('API response:', data);
      if (data.status === 1) {
        setMessage('Order Number Submitted');
        setAlertStatus('success');
      } else {
        setMessage('Order Number failed to Submit');
        setAlertStatus('error');
      }
    } catch (error) {
      // console.error('Error calling API:', error);
    }
    // Reset input value and error state after successful submission
    setOrderNumber('');
  };

  const handleSendReplenComplete = async () => {
    setMessage(null);
    setAlertStatus('success');
    if (!requestNumber.trim()) {
      setMessage('Please enter a value');
      setAlertStatus('error');
      return;
    }
    const parsedValue = parseInt(requestNumber);
    if (isNaN(parsedValue)) {
      setMessage('Please enter a valid request number.');
      setAlertStatus('error');
      return;
    }
    // If the value is an integer, call your API here
    try {
      const response = await fetch(
        `http://${config.serverUrl}:${config.servicePort}/report/sendDeposcoReplenComplete/${parsedValue}`
      );
      const data = await response.json();
      // console.log('API response:', data);
      if (data.status === 1) {
        setMessage('Order Id Submitted');
        setAlertStatus('success');
      } else {
        setMessage('Order Id failed to Submit');
        setAlertStatus('Error');
      }
    } catch (error) {
      // console.error('Error calling API:', error);
    }
    // Reset input value and error state after successful submission
    setRequestNumber('');
  };

  return (
    <Box>
      <Box
        padding={3}
        flexDirection="row"
        display="flex"
        alignItems="center"
        gap={15}
      >
        <Box mt={2} mb={2} display="flex" flexDirection="column">
          <Typography variant="overline" gutterBottom>
            Pick Date
          </Typography>
          <Box flexDirection="row" display="flex" alignItems="center">
            <DatePicker
              wrapperClassName="datePicker"
              selected={startDate}
              startDate={startDate} // Set the start date for the date range picker
              endDate={endDate}
              selectsRange
              maxDate={new Date()}
              onChange={handleDateRangeChange}
            />
            <Button onClick={handleDownloadCSV}>Download</Button>
          </Box>
        </Box>
        <Box mt={2} mb={2}>
          <Typography variant="overline" gutterBottom>
            Send Pick Complete
          </Typography>
          <Box flexDirection="row" display="flex" alignItems="center">
            <TextField
              label="Order Number"
              variant="standard"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              required
            />
            <Button onClick={handleSendPickComplete}>send</Button>
          </Box>
        </Box>
        <Box mt={2} mb={2}>
          <Typography variant="overline" gutterBottom>
            Send Replen Complete
          </Typography>
          <Box flexDirection="row" display="flex" alignItems="center">
            <TextField
              label="Request Number"
              variant="standard"
              value={requestNumber}
              onChange={(e) => setRequestNumber(e.target.value)}
              required
            />
            <Button onClick={handleSendReplenComplete}>send</Button>
          </Box>
        </Box>
        {message != null ? (
          <Alert severity={alertStatus}>{message}</Alert>
        ) : (
          <></>
        )}
      </Box>
      <Paper variant="outlined" sx={{ flex: '60%', height: '45em' }}>
        <DataGrid
          rows={pickData}
          columns={PickCompleteColumns}
          loading={isLoading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 100]}
          columnVisibilityModel={{
            id: false,
          }}
        />
      </Paper>
    </Box>
  );
};

export default Deposco;

export const parseName = (productName) => {
  const lastIndex = productName.lastIndexOf('+');
  var parsedName = productName
    .substring(0, lastIndex)
    .replace(/%([0-9A-Fa-f]{2})|(\d+)/g, function (match, hexCode) {
      // Convert the hex code to a character and return it
      return String.fromCharCode(parseInt(hexCode, 16));
    })
    .replace(/[+]/g, ' ')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    // eslint-disable-next-line quotes
    .replace(/apos/g, "'")
    .replace(/amp/g, '&');

  return parsedName;
};

import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../app.css';

const Navbar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };
  return (
    <AppBar className="navbar" sx={{ backgroundColor: '#333' }}>
      <Toolbar>
        <Box display="flex" flexDirection="row" sx={{ flexGrow: 1 }}>
          <img
            src="/Liftians-LOGO.png"
            alt="Logo"
            style={{ marginRight: '10px', height: '30px', width: 'auto' }}
          />

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Liftians Portal
          </Typography>
        </Box>
        <Box>
          <Button color="inherit" onClick={handleLogout}>
            Log Out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

import React, { useState } from 'react';
import '../../app.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { Container, Alert, Box, TextField, Button } from '@mui/material';

const Landing = () => {
  const [password, setPassword] = useState('');
  const [alertFlag, setAlertFlag] = useState(false);
  const { login } = useAuth();
  const handleLogin = async (event) => {
    setAlertFlag(false);
    event.preventDefault();
    try {
      await login(password);
      navigate('/dashboard');
    } catch (error) {
      setAlertFlag(true);
    }
  };
  const navigate = useNavigate();
  return (
    <Container className="landing-page" maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box
          component="form"
          onSubmit={handleLogin}
          justifyContent="center"
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            width: '100%',
          }}
        >
          <img
            src="/HF-Stacked.png"
            alt="HollywoodFeed"
            style={{ width: '80%', height: 'auto' }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: '240px', height: '40px', padding: '3px' }}
            required
          />
          <Button
            sx={{ width: '240px', height: '40px', padding: '3px' }}
            variant="contained"
            color="primary"
            type="submit"
          >
            Login
          </Button>
          {alertFlag && <Alert severity="error">Authentication Failed</Alert>}
        </Box>
      </Box>
    </Container>
  );
};

export default Landing;

import React from 'react';
import { Box } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Inventory from '../DashPages/Inventory';
import Reports from '../DashPages/Reports';
import Deposco from '../DashPages/Deposco';

const Dashboard = () => {
  return (
    <Box className="app-container">
      <Navbar />
      <Sidebar />
      <Box className="content">
        <Routes>
          <Route path="/" element={<Reports />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="deposco" element={<Deposco />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;

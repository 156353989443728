import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import config from '../../config/config.json';
import { parseName } from '../../util/NameParser';
import { formatDate } from '../../util/DateParser';
import { downloadToCSV } from '../../util/DataToCSV';
import '../../app.css';
const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = inventory.filter((item) => {
    const words = searchTerm.toLowerCase().split(' ');
    return words.every(
      (word) =>
        item.Product_Name.toLowerCase().includes(word) ||
        item.ProductCode.toString().includes(word)
    );
  });

  const handleDownloadCSV = () => {
    const todaysDate = new Date();
    const data = inventory.map(
      ({ ImageURL, Product_ShortName, ...rest }) => rest
    );
    const dateString = formatDate(todaysDate);
    const fileName = `Inventory_Report_${dateString}`;
    downloadToCSV(data, fileName);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `http://${config.serverUrl}:${config.servicePort}/report/inventory`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setInventory(responseData.results);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the fetchData function when component mounts
  }, []);

  return (
    <Container className="content">
      <Typography variant="h4" component="h1" gutterBottom>
        Inventory
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Button variant="contained" color="primary" onClick={handleDownloadCSV}>
        Download
      </Button>
      <Grid container spacing={3}>
        {filteredItems.map((item, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4}>
            <InventoryItem product={item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const InventoryItem = ({ product }) => {
  return (
    <Card sx={{ display: 'flex', marginBottom: 2 }}>
      <CardMedia
        component="img"
        sx={{ width: 100, marginRight: 2 }}
        image={product.ImageURL}
        alt={product.ProductCode}
      />
      <CardContent>
        <Typography variant="body1">
          <strong>Rack ID:</strong> {product.Rack_ID}
        </Typography>
        <Typography variant="body1">
          <strong>Location Barcode:</strong> {product.LocationBarcode}
        </Typography>
        <Typography variant="body1">
          <strong>Product Code:</strong> {product.ProductCode}
        </Typography>
        <Typography variant="body1">
          <strong>Quantity:</strong> {product.Quantity}
        </Typography>
        <Typography variant="body1">
          <strong>Product Name:</strong> {parseName(product.Product_Name)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Inventory;
